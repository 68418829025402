.layout {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 0px rgb(50, 50, 50, 0.8);
  border: 1px solid rgb(200, 200, 200, 0.5);
  background-color: rgb(250, 250, 250);
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus__container {
  font-size: 10rem;

}