.layout {
  width: var(--layout-sidebar-width);
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height));
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
  overflow-y: auto;
  gap: 30px;

  box-sizing: border-box;
}

.txt__datafilter {
  font-family: "Lobster";
  font-size: 1rem;
  font-weight: bold;

  width: calc(100% - 4px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border-radius: 10px;
  margin: 5px 2px 2px 2px;
  box-sizing: border-box;
}




.label__title {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}


.select__layout {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.toggle__buttons__layout {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 30px;
}

.resultfilterselector__layout {
  position: absolute;
  bottom: var(--layout-footer-height);
  width: var(--layout-sidebar-left-width);
  z-index: var(--zindex-overlay-resultfilterselector);
  background-color: rgba(200, 200, 200, 0.8);
  transition: height 0.5s ease;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* overflow-y: none; */
}

.floor__filter__div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.floor__filter__label {
  width: 40%;
  height: 30px;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(50, 50, 50, 0.5);
  border-radius: 10px 0 0 10px;
}

.floor__filter__input {
  width: 60%;
  height: 30px;
  border: 1px solid rgba(50, 50, 50, 0.5);
  border-radius: 0 10px 10px 0;
  padding-left: 20px;
}