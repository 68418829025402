.layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: stretch;
  align-items: stretch;
}

.content {
  width: 100vw;
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height));
  background-color: rgb(245, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card__container {
  box-sizing: border-box;
  /* background-color: white; */
  padding: 10px 50px 10px 50px;
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 20px;
}