.layout {
  width: 100%;
  background-color: var(--color-transparency-bgcolor);
  overflow: none;
}



.canvas__itself {
  background-color: gray;
  width: 100%;
  height: auto;
}