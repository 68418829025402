.layout {
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 0.8rem;
  /* gap: 10px; */
}

.select__header {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  background-color: none;
  font-weight: bold;
  border-bottom: 2px solid rgba(50, 50, 50, 0.5);
  padding-left: 10px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.select__db {
  box-sizing: border-box;
  width: calc(100% - 20px);
  height: 30px;

  background-color: var(--color-transparency-bgcolor);
  border: 1px dashed rgba(200, 200, 200, 0.5);
  margin: 10px;
  text-align: left;
}