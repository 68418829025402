.overalllayout {
  width: 300px;
  height: 335px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.layout {
  width: 300px;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 0px rgb(50, 50, 50, 0.8);
  border: 1px solid rgb(200, 200, 200, 0.5);
}

.layout:hover {
  cursor: pointer;
}

.image__container {
  background-color: rgb(150, 150, 150);
}

.image {
  width: 100%;
  max-height: 150px;
  object-fit: cover;
}

.title__container {
  height: 50px;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgb(230, 230, 230);
  color: rgb(50, 50, 50);
}

.txt__container {
  height: 100px;
  box-sizing: border-box;
  background-color: rgb(50, 50, 50, 0.6);
  color: rgb(230, 230, 230);
  padding: 20px 20px 10px 20px;
  border-radius: 0 0 15px 15px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.txt__row {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.del__button {
  height: 30px;
  width: 100px;
  border-radius: 15px;
  font-size: 1rem;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(150, 0, 0);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

}