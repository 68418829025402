.layout {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: stretch;
  align-items: stretch;
}

.main {
  display: flex;
  justify-content: start;
  align-items: 100%;
}

.menu {
  width: 400px;
  height: 100%;
}

.content {
  flex: 1;
  height: 100%;
  overflow-y: auto;
}