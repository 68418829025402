.layout {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* gap: 50px; */
  /* padding: 50px; */
  background-color: black;
}

.gauge__layout {
  width: 22%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 5px;
  ;
}

.gauge__value {
  width: 80%;
  height: 100px;
  font-size: 1.0vw;
  /* background-color: rgba(200, 200, 200, 0.5); */
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1px;
  ;
}

.gauge__unit {
  width: 80%;
  height: 30px;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}


.gauge__title {
  width: 80%;
  height: 30px;
  font-size: 1.0vw;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}