.layout {
  z-index: var(--zindex-overlay-detailviewer-keymap);
  width: 300px;
  resize: both;
  border: 1px solid rgb(100, 100, 100);
  background-color: white;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  resize: both;
}



.isPinned {
  position: static;
  width: 95%;
  height: auto;
  resize: none;
  border: none;
  padding: 5px;
  border-radius: 10px;
}

.isNotPinned {
  position: absolute;
  right: 100px;
  top: 100px;
  resize: both
}

.topbar {
  background-color: rgba(50, 50, 50, 1);
  height: 25px;
  font-size: 0.8rem;

  border-radius: 5px 5px 0 0;
  color: white;
  cursor: move;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid rgb(200, 200, 200);

}

.btn__minimize {
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  border: none;
  /* border: 1px solid white; */
  border-radius: 1px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: none;
  padding: none;
}

.btn__pinned {
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  border: none;
  /* border: 1px solid white; */
  border-radius: 1px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: none;
  padding: none;
}


.content {
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255.0.5);
  border-radius: 0 0 5px 5px;
}