.layout {
  width: 90%;
  height: 50px;
  display: flex;
  font-size: 0.8rem;
  justify-content: center;
  align-items: center;

}

.label__date {
  width: 20%;
  text-align: right;
  ;
}

.input__date {
  width: 100%;
  padding: 0 5px 0 5px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid rgba(50, 50, 50, 0.5);
  background-color: rgba(255, 255, 255, 0.5);

}