.main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--color-main-bgcolor1);
    color: var(--color-main-bgcolor1-text);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    align-items: center;
    z-index: var(--zindex-overlay-apicheck);
    transition: all 0.3s ease;
}

.img__logo {
    width: 550px;
    height: auto;
}


.btn__expand {
    background-color: var(--color-overlay-bgcolor-opacity);
    color: white;
    border: none;
    font-size: 1.2rem;
}

.login__message {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: yellow;
    /* margin-top: 20px; */
    font-size: 1.5rem;
}

.login__form {
    width: 40%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.login__form__row {
    display: flex;
    justify-content: stretch;
    align-content: stretch;
    gap: 10x;
}

.login__form__label {
    width: 100px;
    height: 50px;
    font-size: 2rem;
}

.login__form__label:hover {
    cursor: pointer;
}

.login__form__input {
    font-size: 1.5rem;
    border-radius: 10px;
    width: 50%;
    padding: 5px;
    margin-top: 5px;
    background-color: var(--color-transparency-bgcolor);
    color: var(--color-main-bgcolor1-text);
    border: none;
    border-bottom: 1px solid white;
    flex: 1;
}

.login__form__button {
    margin: 20px 15px 0px 15px;
    width: 100%;
    border-radius: 10px;
    background-color: var(--color-overlay-bgcolor-opacity);
    color: var(--color-main-bgcolor1-text);
    border: none;
    padding: 5px;
    font-size: 1.5rem;
    transition: all 0.3s ease;
}

@media only screen and (max-width: 600px) {

    .img__logo {
        width: 50%;
        height: auto;
        margin-bottom: 20px;
    }

    .login__message {
        width: 60%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: yellow;
        margin-top: 20px;
        font-size: 0.85rem;
    }

    .login__form {
        width: 60%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .login__form__row {
        display: flex;
        justify-content: stretch;
        align-content: center;
        gap: 3x;
    }

    .login__form__label {
        width: 50px;
        height: 30px;
        font-size: 1rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .login__form__label:hover {
        cursor: pointer;
    }

    .login__form__input {
        font-size: 1rem;
        width: 50%;
        height: 15px;
        border-radius: 5px;
        padding: 5px;
        margin-top: 5px;
        background-color: var(--color-transparency-bgcolor);
        color: var(--color-main-bgcolor1-text);
        border: none;
        border-bottom: 1px solid white;
        /* flex: 1; */
    }

    .login__form__button {
        margin: 20px 15px 0px 15px;
        width: 100%;
        border-radius: 10px;
        background-color: var(--color-overlay-bgcolor-opacity);
        color: var(--color-main-bgcolor1-text);
        border: none;
        padding: 5px;
        font-size: 1.0rem;
        transition: all 0.3s ease;
    }

}