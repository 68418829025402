.layout {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-overlay-bgcolor-opacity);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-siteadder);
}


.input__container {
  width: 500px;
  height: 650px;
  background-color: rgb(250, 250, 250);
  /* padding: 30px; */
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.header {
  width: 100%;
  height: 100px;
  border-radius: 20px 20px 0 0;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

}

.data__row {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

}

.data__row__label {
  box-sizing: border-box;
  font-size: 1.0rem;
  font-weight: bold;
  width: 100%;

  padding: 5px 5px 5px 20px;
  background-color: none;
  color: rgba(50, 50, 50, 0.8);
  /* border-radius: 20px 0 0 0; */
}

.data__row__input {
  box-sizing: border-box;
  font-size: 1.2rem;
  width: 100%;
  border: none;
  border: 1px solid rgba(50, 50, 50, 0.3);
  background-color: rgb(250, 250, 250);
  border-radius: 0 0 20px 0px;
  height: 50px;
  padding: 5px 0 0 5px;
}

.data__row__txtarea {
  box-sizing: border-box;
  font-size: 1.2rem;
  width: 100%;
  border: none;
  border: 1px solid rgba(50, 50, 50, 0.3);
  background-color: rgb(250, 250, 250);
  border-radius: 0 0 20px 0px;
  height: 100px;
  padding: 5px 0 0 5px;
  resize: none;
}

.data__row__fileloader {
  box-sizing: border-box;
  font-size: 1.2rem;
  width: 100%;
  border: none;
  /* border: 1px solid rgba(50, 50, 50, 0.3); */
  background-color: rgb(250, 250, 250);
  border-radius: 0 0 20px 0px;
  height: 100px;
  padding: 5px;
  display: flex;
  justify-content: stretch;
  align-items: center;
}

.photo__preview {}

.message {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: tomato;
  transition: all 0.3s ease;
  animation: blink 0.5s infinite;
}

.data__button {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.buttons {
  width: 150px;
  padding: 10px;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;

}

.btn__ok {
  background-color: rgb(0, 0, 150);
  color: white;
}

.btn__cancel {
  background-color: rgb(150, 0, 0);
  color: white;

}


@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}