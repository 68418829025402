.layout {
    height: var(--layout-header-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-main-bgcolor1);
    color: var(--color-main-bgcolor1-text);
    border-bottom: 1px solid var(--color-main-bgcolor2);
}

.btn__expand {
    background-color: var(--color-overlay-bgcolor-opacity);
    color: white;
    border: none;
    font-size: 1.2rem;
}


.gslogo {
    height: calc(var(--layout-header-height) - 20px);
    width: auto;
    margin-left: 10px;
    /* margin-right: 20px; */
}


.grai_logo {
    height: calc(var(--layout-header-height) - 20px);
    width: auto;
    margin-left: 10px;
    margin-right: 20px;
}

.header__left {
    display: flex;
    min-width: 400px;
    justify-content: left;
    align-items: center;
}


.gslogo:hover {
    cursor: pointer;
}


.header__left__txt {
    font-family: "Lobster";
    font-size: 1.2rem;
}

.lblId {
    margin-left: 20px;
    padding: 5px 10px 5px 10px;
    border-radius: 15px;
    background-color: rgba(250, 250, 250, 0.8);
    color: rgb(50, 50, 50);
    font-size: 0.8rem;
    font-weight: bold;
}

.header__right {
    margin-right: 20px;
    display: flex;
    gap: 5px;

}


.header__site__name {
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__site__label {
    border: none;
    height: 30px;
    padding: 0 10px 0 10px;

    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, 0.5);

    background-color: rgb(0, 0, 0, 0);
    color: rgba(250, 250, 250, 0.5);
    font-size: 1rem;
    margin-right: 10px;
}

.header__site__reload {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, 0.5);
    ;
    margin: 0 5px 0 5px;
    background-color: rgb(0, 0, 0, 0);
    color: rgba(250, 250, 250, 0.5);
}

.header__admin__pannel {
    width: 30px;
    height: 30px;
    font-size: 0.8rem;
    font-weight: bold;
    border-radius: 15px;
    border: 1px solid rgba(250, 250, 250, 0.5);
    ;
    margin: 0 5px 0 5px;
    background-color: rgb(0, 0, 0, 0);
    color: rgba(250, 250, 250, 0.8);
}

.button__header__menu {
    width: 120px;
    height: calc(var(--layout-header-height) - 20px);
    /* border-radius: 10px; */
    background-color: var(--color-nothing);
    color: var(--color-gray-color);
    border: none;
    font-family: "Lobster";
    font-size: 1.0rem;
    transition: color 0.1s ease;
}

.clickable {
    border-bottom: 3px solid var(--color-button-background);
    color: gray;
}

.selected {
    border-bottom: 3px solid var(--color-overlay-bgcolor-opacity-light);

}

.clickable:hover {
    color: yellow;
    font-weight: bolder;
    border-bottom: 3px solid yellow;
    cursor: pointer;

}