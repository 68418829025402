.layout {
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 0.8rem;
  background-color: var(--color-transparency-bgcolor);
  color: white;
  /* gap: 10px; */
}

.select__header {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  background-color: none;
  font-weight: bold;
  border-bottom: 2px solid rgba(50, 50, 50, 0.5);
  padding-left: 10px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.select__db {
  box-sizing: border-box;
  width: calc(100% - 20px);
  height: 30px;

  text-align: center;
  background-color: black;
  color: white;
  border: 1px solid rgba(200, 200, 200, 0.5);
  border-radius: 15px;
  margin: 10px;
  -webkit-appearance: none;
  /* Disables the default styling on iOS */
  -moz-appearance: none;
  /* Disables the default styling in Firefox */
  appearance: none;
  /* Disables the default browser styling */
}