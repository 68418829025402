.layout {
  height: var(--layout-footer-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
}

.footer__label {
  margin-right: 20px;
  font-size: 0.8rem;
  font-weight: bold;
}

.gslogo {
  margin-left: 15px;
  height: 20px;
  width: auto;
}