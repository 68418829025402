.layout {
  width: var(--layout-content-list-width);
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height));
  background-color: rgb(230, 230, 230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: none;
  /* padding-top: 0px; */

}


.menu__container {
  width: calc(var(--layout-sidebar-right-width) - 20px);
  height: 50px;
  margin: 2px 10px 0px 10px;
  border-radius: 10px;
  background-color: var(--color-main-bgcolor1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}


.button__see {
  width: 40%;
  height: 35px;
  border-radius: 10px;
  background-color: var(--color-main-bgcolor2);
  color: var(--color-main-bgcolor2-text);
  border: none;
  cursor: pointer;
  outline: none;
}

.list__container {
  width: 100%;
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height) - 50px - 20px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

}


.dummy__slot {
  width: var(--layout-sidebar-right-width);
  height: 10px;
  background-color: rgb(230, 230, 230);
  border: none;
}