.layout {
  width: calc(100% - 20px);
  height: 200px;
  min-height: 200px;
  margin-top: 5px;
  /* margin-bottom: 5px; */
  border: 1px solid rgba(50, 50, 50, 0.5);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-size: cover;
  /* Adjust the color and transparency */
  background-position: center;
  font-size: 0.8rem;
}


.checked {
  border: 3px solid rgba(100, 0, 0, 1);
  /* background-color: rgba(200, 0, 0, 0.3); */
}

/* .layout:hover {
  filter: brightness(1);
  cursor: pointer;
} */


.description__field {
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
  gap: 5px;
}

.txt__ul {
  padding-left: 5%;
  /* padding-right: 20%; */
}

.txt__li {
  width: 100%;
  text-align: left;
  font-size: 0.9rem;
  /* color: white; */
  /* font-weight: bold; */
  color: rgba(50, 50, 50, 0.9);
  /* text-shadow: 0.5px 0.5px 1px black; */
  /* text-shadow: 0.5px 0.5px 0px black; */
}

.action__icons__container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
  font-size: 1rem;
  background-color: rgba(0, 0, 30, 1);
}


.checked__bg {
  background-color: rgba(50, 0, 0, 1);
}


.action__icon {
  width: 1.5rem;
  height: 1.5rem;
  font-size: 0.8rem;
  /* border: 1px solid rgba(250, 250, 250, 0.5); */
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.action__on {
  background: white;
  color: black;
}

.action__off {
  background: gray;
  color: rgb(20, 20, 20, 0.8);
  border: none;
}