.layout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-msgbox);
  /* visibility: hidden; */
  transition: all 0.3s ease;
}


.body__msgbox__container {
  width: 500px;
  height: 500px;
  border: none;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  background-color: white;

  margin: 0;
  padding: 0;
}

.msgbox__container__title {
  width: 100%;
  height: 150px;
  border-radius: 20px 20px 0 0;
  font-size: 1.5rem;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 1px 1px 0px rgba(100, 100, 100, 0.5);
  color: white;
}

.msgbox__container__content {
  width: 100%;
  height: 200px;
  font-size: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* background-color: white; */
  color: rgb(30, 30, 30);
}

.msgbox__content__message {
  width: 80%;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}


.msgbox__container__buttons {
  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: none;
}

.msgbox__button {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-gray-color);
}