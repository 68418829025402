.layout {
  flex: 1;
  /* width: calc (100vw - var(--layout-sidebar-width)); */
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height));
  background-color: rgb(245, 245, 245);
  overflow: none;
  display: flex;
  justify-content: stretch;
  align-items: stretch;

}


.blank__content__view {
  width: calc(100% - var(--layout-content-list-width));
  height: 100%;
  background-color: rgb(200, 200, 200);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  color: rgba(0, 0, 0, 0.2);
  /* transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out 0.5s; */
  transition: all 0.5s;
}

.gslogo {
  opacity: 0.5;
}

.nodata {
  width: 100% !important;
}