.hide__top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-blackoverlay);
  display: flex;
  justify-content: center;
  align-items: center;
}

.show__top {
  position: absolute;
  left: 0;
  right: 0;
  top: var(--layout-header-height);
  bottom: 0;

}

.layout {
  background-color: var(--color-blackoverlay);
  z-index: var(--zindex-overlay-spinner);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.img__spinner {
  width: 200px;
  height: auto;
}

.message {
  font-size: 1rem;
  animation: blink 1s infinite;
  color: rgba(255, 255, 255, 0.8);
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.btn__emergency__close {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  font-size: 0.5rem;
  border-radius: 50%;
  background-color: (--color-transparency-bgcolor);
  color: rgba(200, 200, 200, 0.8);
}

.btn__emergency__close:hover {
  cursor: pointer;
}