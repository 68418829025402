.layout {
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image__container {
  width: auto;
  height: 80%;
}

.image_wrapper {
  position: relative;
  height: 100%;
  width: fit-content;
}

.image {
  height: 100%;
  width: auto;
}

.scalebar__container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  padding-left: 10px;
  margin: 0;
  color: rgb(255, 255, 255);
  border-top: 1px dashed rgba(100, 100, 100, 0.5);
  height: 25px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.scalebar__image__container {
  width: calc(90% - 10rem);
  height: 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.scalebar_label {
  font-size: 1rem;
  color: white;
}


.scalebar__image {
  width: 100%;
  height: 100%;
  display: block;
}

.scalebar__marker {
  position: absolute;
  top: 0;
  left: 50%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  text-shadow: 1px 1px 0px rgb(0, 0, 0)
}

.scalebar__current__temp {
  position: absolute;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  box-sizing: content-box;
  padding: 10px 15px 10px 15px;
  border-radius: 15px;
  background-color: rgba(50, 50, 50, 0.5);
  color: white;
}