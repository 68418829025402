.layout {
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 0.8rem;
  /* gap: 10px; */
}

.toggle__header {
  box-sizing: content-box;
  width: 100%;
  height: 30px;
  background-color: none;
  font-weight: bold;
  border-bottom: 2px solid rgba(50, 50, 50, 0.5);
  padding-left: 10px;

  display: flex;
  justify-content: left;
  align-items: center;
}

.toggle__container {
  width: 100%;
  /* height: 200px; */
  overflow-y: auto;
  padding: 5px 0px 5px 0px;
  font-size: 1rem;
  /* filter: brightness(1.5); */
  background-color: none;
  border: none;
  border-radius: 0 0 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.toggle__button {
  width: 100%;
  height: 40px;
  /* border-radius: 10px; */
  background-color: noe;
  color: var(--color-main-bgcolor2-text);
  border: none;
  border-left: 10px solid var(--color-main-bgcolor1);
  cursor: pointer;
  outline: none;
  /* transition: background-color 0.3s, color 0.3s, border-color 0.3s; */
}

.active {
  background-color: rgba(50, 50, 50, 0.2);
  font-weight: bold;
  /* border-color: #0056b3; */
}