:root {
  --layout-header-height: 50px;
  --layout-footer-height: 30px;
  --layout-menubar-height: 30px;
  --layout-sidebar-width: 270px;
  --layout-content-list-width: 270px;

  --zindex-overlay-contentviewer-arrow: 500;
  --zindex-overlay-spinner: 1000;
  --zindex-siteadder: 500;
  --zindex-modalmsgbox: 900;

  --color-overlay-bgcolor-opacity-light: rgba(250, 250, 250, 0.5);
  --color-overlay-bgcolor-opacity: rgba(50, 50, 50, 0.5);
  --color-gray-color: rgb(250, 250, 250);
  --color-nothing: rgba(0, 0, 0, 0);






  --color-overlay-initloading: rgba(50, 50, 50, 0.8);
  --color-overlay-msgbox: rgba(50, 50, 50, 0.8);
  --color-overlay-bgcolor: rgb(50, 50, 50);

  --color-overlay-bgcolor-opacity-light: rgba(250, 250, 250, 0.5);
  --color-overlay-bgcolor-opacity-viewermode: rgba(50, 50, 50, 0.5);
  --color-main-bgcolor1: #102e4d;
  --color-main-bgcolor1-text: rgb(250, 250, 250);

  /* --color-main-bgcolor2: #EAEAE0; */
  --color-main-bgcolor2: rgb(250, 250, 250);
  --color-main-bgcolor2-text: rgb(20, 20, 20);

  --color-routetable-title-bgcolor: rgb(11, 36, 71);
  --color-routetable-header-bgcolor: rgba(25, 55, 109, 0.5);
  /* --color-routetable-row-bgcolor: rgba(165, 215, 232, 0.5); */
  --color-routetable-row-bgcolor: rgba(25, 55, 109, 0.1);

  --color-communication-diaglog: rgb(20, 20, 20);
  --color-communication-diaglog-robot: #278AB0;
  --color-communication-diaglog-user: #1DC690;

  --color-main-highlight1: #278AB0;
  --color-main-highlight2: #1DC690;

  --color-transparency-bgcolor: rgba(0, 0, 0, 0);
  --color-darkgray: rgb(50, 50, 50);
  --color-mainblack: rgb(0, 0, 0);

  --color-main-txtcolor: rgb(230, 230, 230);
  --color-main-bgcolor-linebreaker: rgba(255, 255, 255, 0.2);
  --color-main-bgcolor-button: rgba(200, 200, 200, 0.5);
  --color-main-txtcolor-button: rgb(230, 230, 230);

  --color-canvas: rgba(220, 220, 220);
  --color-overlay: rgba(255, 255, 255, 0.8);
  --color-blackoverlay: rgba(0, 0, 0, 0.8);
  --color-linebreaker: rgba(250, 250, 250, 0.5);
  --zindex-overlay-message: 20;
  --zindex-360paronama: 10;

  --zindex-overlay-detailviewer: 500;
  --zindex-overlay-detailviewer-keymap: 550;

  --zindex-subwin-usbcam: 110;
  --zindex-subwin-obstacle: 100;
  --zindex-subwin-thermal: 120;
  --zindex-subwin-controller: 130;
  --zindex-subwin-joystick: 130;
  --zindex-joystick-setup-button: 132;
  --zindex-joystick-setup-window: 134;

  --zindex-overlay-firstloading: 990;
  --zindex-overlay-apicheck: 980;
  --zindex-overlay-initloading: 970;
  --zindex-overlay-msgbox: 975;
  --zindex-overlay-navigation-launcher: 976;
  --zindex-overlay-viewermode: 1000;

  --zindex-overlay-reload: 985;
  --zindex-overlay-resultfilterselector: 500;



  --layout-sidebar-dbselect-bar: 80px;
  --layout-cmdcontainer-height: 110px;
  --layout-sidebar-dialogbox-title-height: 0px;
  --layout-sidebar-dialogbox-height: 230px;

}

/*** [for debug] ***/
/* * { 
  border: 1px solid red !important;
  box-sizing: border-box;
} */


html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;

  padding-top: env(safe-area-inset-top, 0);
  padding-right: env(safe-area-inset-right, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);
  padding-left: env(safe-area-inset-left, 0);

}


#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: none;
}


/******************
* S: Common
*******************/
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", "Noto Sans KR", sans-serif;
}

button {
  transition: transform 0.1s ease;
}

button:active {
  animation: clicking 0.5s;
}

button:hover {
  cursor: pointer;
  filter: brightness(1.5);
  font-weight: bolder;
}


/********** scroll style ****************/
/* This styles the scrollbar track */
::-webkit-scrollbar-track {
  background-color: rgba(200, 200, 200, 0.5);
}

/* This styles the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 10px;
}

/* This styles the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-main-bgcolor1);
}

/* This styles the scrollbar itself (width, height) */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}


/*** layout ***/
.vdiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hdiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabled {
  pointer-events: none;
  color: var(--color-darkgray);
  /* background-color: tomato; */
}

.clickedToggle {
  cursor: pointer;
  animation: blink 0.5s infinite;
}

.hidden {
  visibility: hidden;
  display: none;
}


.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
  /* Center the spinner */
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}



/*** color ***/
.alert {
  color: yellow;
}

.warning {
  color: tomato;
}

.bg__warnning {
  color: rgb(11, 36, 71);
  font-weight: bold;
}

/*** animation ***/
.blink-slow {
  animation: blink 1s infinite;
}

.tr-blink-slow {
  color: tomato;
  animation: blink 1s infinite;
}

.blink-fast {
  animation: blink 0.5s infinite;
}

.blink-slow-once {
  animation: blink 1s ease-in-out 1;
}

.blink-slow-threetimes {
  animation: blink 1s ease-in-out 3;
}

.blink-fast-once {
  animation: blink 0.5s 3s;
}


@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 1s ease-in-out;
}

.fade-out {
  animation: fadeOut 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}


@keyframes clicking {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes move {
  0% {
    transform: translateX(0) rotateY(0);
  }

  50% {
    transform: translateX(5px) rotateY(0);
  }

  51% {
    transform: translateX(5px) rotateY(180deg);
  }

  100% {
    transform: translateX(-5px) rotateY(180deg);
  }
}

@keyframes sit {
  0% {
    transform: translateY(0);
    clip-path: inset(0);
  }

  100% {
    transform: translateY(0.3rem);
    clip-path: inset(0 0 25% 0);
  }
}

@keyframes stand {
  0% {
    transform: translateY(0.3rem);
    clip-path: inset(0 0 25% 0);
  }

  100% {
    transform: translateY(0px);
    clip-path: inset(0);
  }
}


/*** text style ***/
.txt__logo {
  font-family: "Lobster";
  font-size: 4rem;
}

.txt__header {
  font-family: "Lobster";
  font-size: 1.5rem;
}

.txt__large {
  font-size: 1.5rem;
}

.txt__normal {
  font-size: 1.5rem;
}

.txt__small {
  font-size: 1.0rem;
}

.txt__xsmall {
  font-size: 0.7rem;
}


@media only screen and (max-width: 600px) {


  /*** text style ***/
  .txt__logo {
    font-family: "Lobster";
    font-size: 2rem;
  }

  .txt__header {
    font-family: "Lobster";
    font-size: 1.5rem;
  }

  .txt__large {
    font-size: 1.5rem;
  }

  .txt__normal {
    font-size: 1.0rem;
  }

  .txt__small {
    font-size: 0.8rem;
  }

  .txt__xsmall {
    font-size: 0.5rem;
  }

}

/******************
* E: Common
*******************/







/******************
* S: first loading page
*******************/
.body__firstloading__page {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  justify-content: center;
  z-index: var(--zindex-overlay-firstloading);
  background-color: var(--color-overlay-bgcolor);
  color: var(--color-main-bgcolor1-text);
  font-size: 1rem;
  font-weight: bold;
  transition: all 1s ease-in-out;

}

.firstloading__page__logo {
  width: 300px;
  height: auto;
  animation: blink 1s infinite;
}

.firstloading__page__label {
  animation: blink 1s infinite;
}

/******************
* E: first loading page
*******************/



/******************
* S: login page
*******************/
.body__loginpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-apicheck);
  transition: all 0.3s ease;
}

.body__loginpage img {
  width: 500px;
  height: auto;
  margin-bottom: 20px;
}

.body__loginpage__container {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}

.body__loginpage p {
  margin-bottom: 25px;
}

.loginpage__container__line {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.loginpage__container__line label {
  width: 10%;
}

.loginpage__container__line input {
  font-size: 1.5rem;
  width: 50%;
  padding: 5px;
  margin-top: 5px;
  /* margin-right: 20px; */
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-bgcolor1-text);
  border: none;
  border-bottom: 1px solid white;
}

.loginpage__button {
  margin: 20px 15px 0px 15px;
  width: 50%;
  border-radius: 10px;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  border: none;
  padding: 5px;
  transition: all 0.3s ease;
}


.loginpage__container_message {
  color: yellow;
  text-align: center;
  width: 100%;
  margin-top: 30px;
}

/******************
* E: login page
*******************/



/******************
* S: init loading page
*******************/

.body__initloading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay-initloading);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-initloading);
  visibility: hidden;
  transition: all 0.3s ease;
  font-size: 2rem;
}

.initloading__image {
  width: 100px;
  height: 100px;
}


.initloading__message {
  width: 70vw;
  text-align: center;
}

/******************
* E: init loading page
*******************/



/******************
* S: reloading page
*******************/
.body__reloadpage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  /* Black with 50% opacity */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-reload);
  visibility: hidden;
  transition: all 0.3s ease;
}

.body__reloadpage__button {
  padding: 10px 20px;
  max-width: 80%;
  background-color: tomato;
  color: var(--color-main-bgcolor1-text);
  border: none;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
}


/******************
* E: reloading page
*******************/

/******************
* S: viewer mode layer
*******************/
.body__viewermode__layer {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background-color: var(--color-overlay-bgcolor-opacity-viewermode);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-viewermode);
  visibility: hidden;
  transition: all 0.3s ease;
  font-size: 1.2rem;
  font-weight: bold;
}

/******************
* E: viewer mode layer
*******************/


/******************
* S: navigation launcher layer
*******************/
.body__navigaiton__launcher {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-navigation-launcher);
  visibility: hidden;
  transition: all 0.3s ease;
}


.btn__start__space__navigation {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  margin: 0;
  padding: 0;

  font-family: "Lobster";
  font-size: 3rem;
  /* font-weight: bold; */

  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  /* border: 5px solid rgba(0, 0, 200, 0.5); */
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 0 rgba(100, 100, 100, 0.5);
  color: var(--color-main-bgcolor1);

}

/******************
* E: navigation launcher layer
*******************/

/******************
* S: msgbox page
*******************/
.body__msgbox {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-msgbox);
  visibility: hidden;
  transition: all 0.3s ease;
}

.body__msgbox__container {
  width: 500px;
  height: 500px;
  border: none;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  background-color: white;

  margin: 0;
  padding: 0;
}

.msgbox__container__title {
  width: 100%;
  height: 150px;
  border-radius: 20px 20px 0 0;
  font-size: 1.5rem;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;

  text-shadow: 1px 1px 0px rgba(100, 100, 100, 0.5);
  color: white;
}

.msgbox__container__content {
  width: 100%;
  height: 200px;
  font-size: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  /* background-color: white; */
  color: rgb(30, 30, 30);
}

.msgbox__content__message {
  width: 80%;
  height: 150px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.msgbox__content__input {
  width: 80%;
  height: 50px;
  font-size: 1rem;

  background-color: rgba(200, 200, 200, 0.5);
  border: none;
  border-radius: 25px;
  text-align: center;
}

.msgbox__content__select {
  width: 80%;
  height: 50px;
  font-size: 1rem;

  background-color: rgba(200, 200, 200, 0.5);
  border: none;
  border-radius: 25px;
  text-align: center;
}


.msgbox__container__buttons {
  width: 100%;
  height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: none;
}

.msgbox__button {
  width: 120px;
  height: 40px;
  border: none;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}

/******************
* E: msgbox page
*******************/



/******************
* S: page header (main logo & status bar)
*******************/
.body__header {
  height: var(--layout-header-height);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border-bottom: 1px solid var(--color-main-bgcolor2);
  box-sizing: border-box;
}

.body__header__left {
  display: flex;
  min-width: 400px;
  justify-content: left;
  align-items: center;
}

.body__header__left:hover {
  cursor: pointer;
}

.header__left__img {
  height: 31px;
  width: auto;
  margin-left: 20px;
  margin-right: 20px;
}


.body__header__right {
  min-width: 300px;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  /* color: white; */
  margin-right: 20px;
}

.body__header__right i,
svg {
  /* min-width: 25px; */
  max-width: 25px;
  max-height: 25px;
  transition: all 0.3s ease;
  text-align: center;
  margin: 0;
  padding: 0;
}

.btn__map__editor {
  width: 30px;
  height: 30px;
  margin: 0px;
  background-color: var(--color-main-bgcolor2);
  color: var(--color-main-bgcolor2-text);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}



.video__quality_selector {
  border: 1px solid var(--color-main-bgcolor1-text);
  padding: 2px 10px 2px 10px;
  border-radius: 10px;
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-bgcolor1-text);
}

.video__quality_selector option {
  color: var(--color-main-bgcolor2-text);
}

/******************
* E: page header (main logo & status bar)
*******************/



/******************
* S: Sensor status bar
*******************/
.sensor__container {
  height: var(--layout-sensorbar-height);
  width: 100%;
  display: flex;
  gap: 20px;
  justify-content: space-around;
  overflow-x: auto;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border-bottom: 1px solid var(--color-overlay-bgcolor-opacity);
  box-sizing: border-box;
}

.sensor__gauge {
  min-width: 150px;
  height: 70%;
  border-radius: 10px;
  /* background-color: var(--color-overlay-bgcolor-opacity-light); */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
  padding: 0 10px 0 10px;
}

.sensor__gauge__reload {
  width: 30px;
  height: 30px;
  background-color: var(--color-overlay-bgcolor-opacity);
  border-radius: 50%;
  color: rgba(250, 250, 250, 0.3);
  font-size: 1rem;
}


.txt__sensor__title {
  font-size: 0.8rem;
}

.txt__sensor__value {
  font-family: 'Orbitron';
  font-size: 1.3rem;
}

.txt__sensor__unit {
  font-size: 1.0rem;
  letter-spacing: -0.1rem;
}

/******************
* E: Sensor status bar
*******************/


/******************
* S: Center body 
*******************/
.body__center {
  width: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  background-color: var(--color-main-bgcolor2);
  color: var(--color-main-bgcolor2-text);
  position: relative;
}

.body__center__left__container {
  /* width: calc(100% - var(--layout-sidebar-width));
   */
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: stretch;
  align-items: stretch; */
  background-color: var(--color-main-bgcolor2);
  border-right: 1px solid var(--color-overlay-bgcolor-opacity);
}

/******************
* E: Center body 
*******************/


/******************
* S: Center Navigation view
*******************/

.map__container {
  height: calc(100% - var(--layout-cmdcontainer-height));
  width: 100%;
  margin: 1px;
  position: relative;
  display: flex;
  overflow: auto;
  box-sizing: border-box;
}


.map__canvas {
  position: absolute;
  left: 0;
  top: 0;
  /* width: 100%;
  height: 100%; */
}

/********* 360 VIEWER STYLE ************/
.panorama__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 300px;
  height: 300px;
  margin: 0;
  padding: 0;

  background: none;
  display: flex;
  flex-direction: column;
  z-index: var(--zindex-360paronama);
  visibility: hidden;
  resize: both;
}

.panorama__container__bar {
  width: 100%;
  height: 30px;
  margin: 0;
  padding: 0;
  background: none;
}

.btn__panorama__closer {
  width: 25px;
  height: 25px;
  float: right;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  font-size: 15px;
  border-radius: 50%;
  border: none;
}

.panorama__container {
  width: 100%;
  height: calc(100% - 25px);
  background-color: var(--color-main-bgcolor2);
  border-radius: 20px;
  border: none;
}

.panorama__container:hover {
  cursor: pointer;
}


/******* LOADING DISPLAY STYLE **********/
.main__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: var(--zindex-overlay-navigation-viewer);
  visibility: hidden;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
  font-size: 1.5rem;
}

/* 
.overlay__container {
  display: flex;
  flex-direction: column;
}

.overlay__container__input {
  border: none;
  border-bottom: 1px dashed gray;
  background-color: var(--color-overlay-bgcolor-opacity);
  color: var(--color-main-bgcolor1-text);
} */




/********* message area **********/
.msgbox__container {
  width: 100%;
  height: var(--layout-msgboxbar-height);
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border-bottom: 1px solid var(--color-overlay-bgcolor-opacity);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-size: 1rem;
  transition: all ease-in-out;
}

.sparsity__container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  align-items: center;
  margin-left: 20px;
}

.msgbox__sparsity__txt {
  /* color: rgb(200, 200, 200); */
  font-size: 0.8rem;
}

.msgbox__sparsity {
  width: 15px;
  height: 15px;
  padding: 0;
  border-radius: 50%;
  background-color: var(--color-transparency-bgcolor);
  border: 1px solid white;
}

.msgbox__area__container {
  width: auto;
  flex: 1;
  border: none;
  color: var(--color-main-bgcolor2-text);
  font-weight: bold;
  background-color: var(--color-transparency-bgcolor);
  /* width: 80%; */
  user-select: none;
  /* text-align: center; */
  margin-right: 50px;
}

.msgbox__area {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sidebar__container {
  width: var(--layout-sidebar-width);
  background-color: var(--color-main-bgcolor2);
  color: var(--color-main-bgcolor2-text);
  /* height: calc(100% - var(--layout-header-height) - var(--layout-sensorbar-height) - var(--layout-msgboxbar-height) - var(--layout-footer-height)); */
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

/******* project selection area **********/

.project__selection__container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  border-bottom: 1px solid var(--color-main-bgcolor-linebreaker);
}

.project__menu__bar {
  height: var(--layout-sidebar-dbselect-bar);
  width: calc(100% - 10px);

  box-sizing: border-box;
  margin: 5px;
  border-radius: 10px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  /* border-bottom: 1px dashed var(--color-overlay-bgcolor-opacity); */
  /* 
    color: var(--color-main-bgcolor2-text); */
}

.menu__bar__table {
  width: 90%;
  border-collapse: collapse;
  font-size: 0.8rem;
  table-layout: fixed;
}

.btn__patrolroute__save {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border: 1px solid rgba(50, 50, 50, 0.5);
  font-size: 1.1rem;
  margin-right: 5px;
}


.btn__patrolroute__reload {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  /* display: none; */
  background-color: rgba(200, 0, 0, 0.5);
  color: var(--color-main-bgcolor1-text);
  border: 1px solid rgba(50, 50, 50, 0.5);
  font-size: 1.1rem;
}

/* 
.btn__projectdb__reload {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
}

.select__list {
  height: 30px;
  flex-grow: 1;
  text-align: center;
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-bgcolor2-text);
  border-radius: 15px;
  padding: 0 5px 0 5px;
  margin-right: 50px;
} */

/* 
.btn__projectdb__addtask {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 50%;
  text-align: center;
  background-color: rgb(50, 50, 50);
  color: rgb(230, 230, 230);
} */



.navigation__viewer__mode {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100vw - 20px);
  height: 250px;
  background-color: rgba(50, 50, 50, 0.5);
  z-index: var(--zindex-overlay-navigation-viewer);
  visibility: hidden;
}

/********* target table area **********/


.planning__container {
  max-height: calc(100vh - var(--layout-header-height) - var(--layout-sensorbar-height) - var(--layout-msgboxbar-height) - var(--layout-footer-height) - var(--layout-sidebar-dialogbox-title-height) - var(--layout-sidebar-dialogbox-height) - var(--layout-sidebar-dbselect-bar));
  height: calc(100vh - var(--layout-header-height) - var(--layout-sensorbar-height) - var(--layout-msgboxbar-height) - var(--layout-footer-height) - var(--layout-sidebar-dialogbox-title-height) - var(--layout-sidebar-dialogbox-height) - var(--layout-sidebar-dbselect-bar));

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-color: var(--color-main-bgcolor); */
  color: var(--color-maintext);
  box-sizing: border-box;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  /* border: 1px rgba(200, 200, 200, 0.5); */
  /* background-color: rgb(250, 250, 250); */
  /* border-top: 0.5px dashed var(--color-linebreaker);
  border-bottom: 0.5px dashed var(--color-linebreaker); */
  /* table-layout: fixed; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.add__planning__box__container {
  width: calc(100% - 20px);
  height: 35px;
  /* border-radius: 15px; */
  display: flex;
  margin: 10px;
  padding: 0;
  justify-content: space-around;
  align-items: center;
  /* gap: 30px; */
  /* background-color: var(--color-overlay-bgcolor-opacity-light); */
  border: none;
  border-top: 1px solid var(--color-overlay-bgcolor-opacity-light);
  border-bottom: 1px solid var(--color-overlay-bgcolor-opacity-light);
}

.add__planning__box__selector {
  box-sizing: border-box;
  width: 150px;
  height: 25px;
  font-size: 0.8rem;
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-bgcolor1);
  border-radius: 10px;
}

.add__planning__box__btn {
  box-sizing: border-box;
  width: 50px;
  height: 25px;
  font-size: 0.7rem;
  border-radius: 10px;
  margin: 0;
  padding: 0;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
}

.planning__box {
  box-sizing: border-box;

  width: calc(100% - 20px);
  margin: 5px 10px 5px 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border: none;
  border-radius: 5px;
}

.planning__box__bar {
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  min-height: 30px;
  /* background-color: var(--color-overlay-bgcolor-opacity); */
  background-color: var(--color-routetable-title-bgcolor);
  color: var(--color-main-bgcolor1-text);
  border-radius: 5px 5px 0 0;
  font-size: 0.8rem;
  font-weight: bold;
  /* text-align: left; */
  padding-left: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.planning__box__close__button {
  width: 25px;
  height: 25px;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-bgcolor1-text);
  border: none;
  justify-content: center;
  align-items: center;

}



.planning__box__del__button {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: none;
  background-color: rgba(255, 99, 71, 0.2);
  color: white;
  font-size: 0.7rem;
  padding: 0;
  margin: 0;
}


.planning__box__completion_marker {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid rgba(50, 50, 50, 0.5);
}

.planning__box__edit__button {
  width: 50px;
  height: 20px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-size: 0.8rem;
}

.planning__box__task__selector {
  width: 90px;
  height: 30px;
  border-radius: 5px;
  background-color: var(--color-transparency-bgcolor);
  border: 0.5px soild var(--color-overlay-bgcolor-opacity);
  font-size: 0.8rem;
  text-align: center;
}

.planning__box__task__icon {
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 50%;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.route__container {
  box-sizing: border-box;
  width: 100%;
  font-size: 0.8rem;
  margin: 0;
  /* table-layout: fixed; */
  /* border-color: rgb(50, 50, 50); */
}

.route__header {
  box-sizing: border-box;
  width: 100%;
  height: 30px;

  display: grid;
  padding-left: 2%;
  padding-right: 2%;
  grid-template-columns: 8% 8% 10% 30% 40%;

  margin-bottom: 2px;
  border: none;
  border-top: 1px solid rgba(200, 200, 200, 0.5);

  background-color: var(--color-routetable-header-bgcolor);
  color: white;
}

.route__header>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.route__body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.route__body__row {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding-left: 2%;
  padding-right: 2%;
  grid-template-columns: 8% 8% 10% 30% 40%;
  background-color: var(--color-routetable-row-bgcolor);
  margin-bottom: 2px;
}


.route__body__row>div {
  display: flex;
  justify-content: center;
  align-items: center;

}

.row__active__mouseover:hover {
  cursor: default;
  filter: brightness(2.0);
  font-weight: bolder;
}

.lbl__complete__marker {
  font-weight: bold;
}

.highlighted-row {
  background-color: rgba(200, 200, 0, 0.5);
}

/********* command button area **********/
.button__container {
  width: calc(100% - 4px);
  height: var(--layout-cmdcontainer-height);
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 10px;
  box-sizing: border-box;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  /* border-top: 1px solid var(--color-overlay-bgcolor-opacity); */
  padding: 0 20px 0 20px;
  margin: 0 2px 2px 2px;
  border-radius: 10px;
  border: 1px solid var(--color-main-bgcolor1-text);
}

.button__container__cmd__outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  gap: 4px;
}

.button__container__cmd__outer__label {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--color-overlay-bgcolor-opacity-light);
  color: var(--color-overlay-bgcolor-opacity-light);
  margin-bottom: 2px;
}

.btn {
  box-sizing: border-box;
  border: 1px solid var(--color-main-bgcolor1-text);
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
}

.button__container__cmd {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.cmd {
  width: 65px;
}

.top {
  height: 32px;
  border-radius: 10px 10px 0 0;
  margin-bottom: 1px;
  /* border-bottom: 1px solid var(--color-main-bgcolor2); */
}

.bottom {
  height: 32px;
  border-radius: 0 0 10px 10px;
}

.button__container__routing {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}


.routing {
  /* background-color: var(--color-main-bgcolor); */
  font-size: 0.8rem;
  width: 65px;
  height: 65px;
  border-radius: 10px;
  line-height: 0.8rem;
}

.initpose {
  /* border-radius: 30px; */
  /* background-color: var(--color-main-bgcolor2); */
  border: 2px solid var(--color-darkgray);
  box-sizing: border-box;
  /* color: var(--color-main-bgcolor2-text); */
  /* margin-right: 10px; */
}

.button__container__nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.drive {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
}

.button__container__result {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
}

.result {
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0;
  line-height: 0.8rem;
}

/* 
.overlay__test {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 6000;
  background-color: var(--color-overlay-bgcolor-opacity);
} */

.button__container__mode {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
}

.mode {
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0;
}

.btnstyle__div {
  background-color: var(--color-main-bgcolor1);
  border: 1px solid var(--color-main-bgcolor1-text);
  color: var(--color-main-bgcolor1-text);
  box-sizing: border-box;
  font-size: 0.8rem;
  width: 65px;
  height: 65px;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  /* line-height: 0.8rem; */
}

.btnstyle__div * {
  width: 100%;
  flex-grow: 1;
  text-align: center;
  color: var(--color-main-bgcolor1-text);
  border: none;
}

.btn__move__updown {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  color: var(--color-main-bgcolor1-text);
  background-color: var(--color-main-bgcolor1);
  font-size: 0.8rem;
}

.btn__toggle__onoff {
  width: 40px;
  /* margin-left: 5px;
  margin-right: 5px; */
  transition: all 0.3s ease-in-out;
}

.btn__toggle__onoff__short {
  width: 30px;
  /* margin-left: 5px;
  margin-right: 5px; */
  transition: all 0.3s ease-in-out;
}

/******************
* E: Center Navigation view
*******************/



/******************
* S: Center - aside bar
*******************/

.chat__outer__container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  width: calc(100% - 10px);
  min-height: calc(var(--layout-sidebar-dialogbox-height) + var(--layout-sidebar-dialogbox-title-height));
  max-height: calc(var(--layout-sidebar-dialogbox-height) + var(--layout-sidebar-dialogbox-title-height));
  padding: 0 0 10px 0;
  /* border-top: 1px solid var(--color-overlay-bgcolor-opacity); */

  /* border-bottom: 1px solid var(--color-main-bgcolor-linebreaker); */
}

.chat__container__title {
  width: 100%;
  min-height: var(--layout-sidebar-dialogbox-title-height);
  max-height: var(--layout-sidebar-dialogbox-title-height);
  /* background-color: var(--color-overlay-bgcolor-opacity-light); */
  border-top: 1px dashed var(--color-overlay-bgcolor-opacity);
  box-sizing: border-box;
  margin: 0 0 2px 0;
  padding: 0;
  font-size: 0.8rem;
  font-weight: bold;
  padding-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0 0 10px 10px;
  margin-left: 2px;
  margin-right: 2px;
}

.chat__container {
  box-sizing: border-box;
  width: 100%;
  /* height: 100%; */
  min-height: var(--layout-sidebar-dialogbox-height);
  max-height: var(--layout-sidebar-dialogbox-height);

  /* flex-grow: 1;
  height: auto; */
  /* max-height: calc((100vh - 51px - 51px - 31px - 20vw)); */
  /* max-height: 300px; */
  /* height: 100%; */
  /* max-height: 600px; */
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
  /* padding: 5px; */

}

.chat__container__dialog {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  /* height: 100%; */
  max-height: var(--layout-sidebar-dialogbox-height);
  background-color: var(--color-communication-diaglog);
  /* flex-grow: 1; */
  /* background: rgba(0, 10, 50, 1); */
  /* border: 1px solid darkblue;
  border-radius: 10px; */
  /* padding: 10px; */
  /* color: rgb(165, 215, 232); */
  overflow-y: scroll;
  /* box-shadow: inset 0 2rem 2rem -2rem rgba(255, 255, 255, 0.1),
    inset 0 -2rem 2rem -2rem rgba(255, 255, 255, 0.1); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
  /* padding-top: 20px;
  padding-bottom: 20px; */
  border: 10px solid var(--color-communication-diaglog);
  /* border-radius: 10px 0 0 10px; */
  border-radius: 10px;
  margin: 4px;
  /* margin-bottom: 2px; */
  /* margin-right: 2px; */
}

.chat__container__dialog__robot {
  width: calc(100% - 30px);
  margin-bottom: 7px;
  margin-left: 5px;
  border-left: 1px solid var(--color-communication-diaglog-robot);
  /* background-color: var(--color-transparency-bgcolor); */
  color: var(--color-communication-diaglog-robot);
  /* border-radius: 0 5px 5px 0; */
  /* padding: 5px 10px 5px 10px; */
  padding: 0 0 0 5px;
  font-size: 0.8rem;
}

.chat__container__dialog__user {
  width: calc(100% - 30px);
  margin-bottom: 7px;
  margin-left: 5px;
  border-left: 1px solid var(--color-communication-diaglog-user);
  /* background-color: var(--color-overlay-bgcolor-opacity-light); */
  color: var(--color-communication-diaglog-user);
  /* border-radius: 0 5px 5px 0;
  padding: 5px 10px 5px 10px; */
  padding: 0 0 0 5px;
  font-size: 0.8rem;
}


/******************
* E: Center - aside bar
*******************/



/******************
* S: footer
*******************/
.body__footer {
  width: 100%;
  height: var(--layout-footer-height);
  box-sizing: border-box;
  background: var(--color-darkgray);
  display: flex;
  justify-content: stretch;
  padding-left: 10px;
  padding-right: 10px;
  align-items: center;
  border-top: 1px solid rgb(100, 100, 100);
}

.body__footer__streambtn {
  min-width: 150px;

  background-color: rgba(50, 50, 50, 1);
  color: rgb(250, 250, 250);

  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;

  text-align: center;
  margin-left: 10px;
  padding: 2px 0 2px 0;
}

.body__footer__streambtn:hover {
  cursor: pointer;
  background-color: rgb(150, 150, 150);
}

.body__footer__statusbar1 {
  flex: 3;
  color: rgb(250, 250, 250);
  text-align: center;
}

.body__footer__statusbar2 {
  flex: 1;
  color: rgb(250, 250, 250);
  text-align: center;
}

.body__footer__label {
  /* flex: 2; */
  margin-right: 20px;
  color: rgba(200, 200, 200);
}

/******************
* E: footer
*******************/


/******************
* S: Joystick Controller
******************/
.body__joystick {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  background-color: var(--color-main-bgcolor1);
  border-top: 0.1vh solid rgb(100, 100, 100);
  border-bottom: 0.1vh solid rgb(255, 255, 255);
}

.subwindow_joystick_closebutton {
  float: right;
  margin-right: 5px;
  height: 20px;
  width: 20px;
  /* border-radius: 50%; */
  color: white;
  font-size: 0.7rem;
  background-color: var(--color-transparency-bgcolor);
  /* border: 1px solid rgba(255, 255, 255, 0.5); */
  border: none;
}

.joystick__mode {
  position: absolute;
  left: 10px;
  bottom: 20px;
  z-index: var(--zindex-subwin-joystick);
  width: 40px;
  height: 40px;
  background-color: var(--color-transparency-bgcolor);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  /* border-radius: 2vw; */
  /* background-color: rgba(255, 255, 255, 0.2); */
  /* border: 0.2vh dashed var(--color-mainwhite); */
}


.joystick__mode span {
  font-size: 0.8rem;
  font-family: "Roboto", "Noto Sans KR", sans-serif;
}

.joystick__setup__button {
  position: absolute;
  right: 10px;
  bottom: 20px;
  z-index: var(--zindex-joystick-setup-button);
  background-color: var(--color-transparency-bgcolor);
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  /* color: tomato; */
}

.joystick__setup__window {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: var(--zindex-joystick-setup-window);
  width: 200px;
  height: 200px;
  /* width: 0;
  height: 0; */
  transition: all 0.3s ease-in-out;
  background: var(--color-blackoverlay);
  color: var(--color-main-txtcolor);
  visibility: hidden;
  opacity: 0;
  border-radius: 10px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-size: 1rem;
  gap: 1rem;
  padding-top: 1rem;

}

.joystick__setup__window input {
  width: 50px;
  font-size: 1rem;
  background-color: var(--color-blackoverlay);
  /* border: none; */
  border-radius: 10px;
  color: var(--color-main-txtcolor);
  padding: 10px;
  text-align: right;
  margin: 2px;
}

.joystick__setup__window table {
  width: 90%;
}

.joystick__setup__window__enter {
  width: 80%;
  height: 30px;
  font-size: 1rem;
  /* padding: 10px; */
  background-color: var(--color-main-bgcolor1-text);
  border-radius: 10px;
  color: var(--color-mainblack);
}

.joystick__setup__window__xmark {
  position: absolute;
  background-color: var(--color-transparency-bgcolor);
  color: var(--color-main-txtcolor);
  width: 20px;
  height: 20px;
  border: 1px solid var(--color-main-txtcolor);
  left: 10px;
  top: 20px;
  font-size: 0.7rem;
}

.joystick__outercircle {
  position: relative;
  width: 250px;
  height: 250px;
  /* border: 5px solid #333; */
  background-color: var(--color-main-bgcolor1-text);
  border-radius: 50%;
  overflow: hidden;
}

.joystick__controlcircle {
  position: absolute;
  width: 30%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-main-bgcolor1);
  color: rgba(255, 255, 255, 0.3);
  font-size: 3vh;
  border-radius: 50%;
  cursor: grab;
  touch-action: none;
  font-size: 1rem;
  color: var(--color-main-bgcolor1-text);
}

/******************
* E: Joystick Controller
******************/





/******************
* S: Key control pad
*******************/
.main__video__controller {
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.8); Black with 50% opacity */
  opacity: 1;
  /* display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center; */
  box-sizing: border-box;
}

/* .video__controller__pad {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
} */

.video__controller__button {
  box-sizing: border-box;
  width: 25%;
  height: 25%;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: centert;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(20, 20, 20, 0.8);
  color: rgba(230, 230, 230, 1);
  border-radius: 10px;
  border: 1px solid #ccc;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.headup {
  box-sizing: border-box;
  width: 25%;
  height: 25%;
  float: left;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: none;

  padding: 0px;
  background-color: rgba(250, 250, 250, 1);
  color: rgb(50, 50, 50);
  border: none;
  text-shadow: none;
  box-shadow: none;
  cursor: default;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* .headup * {
  width: 100%;
  text-align: center;
} */

.txt__headup__title {
  font-size: 0.7rem;
}

.txt__headup__value {
  font-family: 'Orbitron';
  font-size: 1.3rem;
}

.txt__headup__percent {
  font-size: 0.7rem;
}

.txt__keypad__fn {
  font-size: 1.0rem;
}

.txt__keypad__num {
  font-size: 0.7rem;
}

/******************
* E: Key control pad
*******************/




/****************
* S: sub floating window 
*********************/
.subwindow {
  position: absolute;
  border: 1px solid rgb(100, 100, 100);
  background-color: rgb(0, 0, 0);
  resize: both;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.thermal__style {
  z-index: var(--zindex-subwin-thermal);
  visibility: hidden;
  opacity: 0.5;
  top: 200px;
  left: 50px;
  width: 300px;
  height: 300px;
}

.usbcam__style {
  z-index: var(--zindex-subwin-usbcam);
  visibility: visible;
  top: 160px;
  left: 10px;
  width: 400px;
  height: 400px;
}


.obstacle__style {
  z-index: var(--zindex-subwin-obstacle);
  visibility: visible;
  top: 570px;
  left: 10px;
  width: 200px;
  height: 225px;
}

.controller__style {
  z-index: var(--zindex-subwin-controller);
  visibility: hidden;
  bottom: 300px;
  left: calc(50% - 150px);
  width: 300px;
  height: 325px;
  /* resize: none; */
}

.joystick__style {
  z-index: var(--zindex-subwin-joystick);
  visibility: hidden;
  bottom: 300px;
  right: 300px;
  width: 300px;
  height: 325px;
  resize: none;
  opacity: 0.8;
}

.subwindow::after {
  content: "";
  display: table;
  clear: both;
}

.subwindow__bar {
  background-color: rgba(10, 10, 10, 0.7);
  height: 25px;
  font-size: 0.8rem;
  font-weight: bold;
  color: white;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid rgb(200, 200, 200);

}

.subwindow__content {
  width: 100%;
  cursor: move;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;
}

.height__usbcam {
  height: calc(100% - 25px);
}

.height__thermal {
  height: calc(100% - 50px);
}

.height__obstacle {
  height: calc(100% - 25px);
}

.height__controller {
  height: calc(100% - 25px);
  background: none;
  cursor: pointer;
}


.height__joystick {
  height: calc(100% - 25px);
  background: none;
  cursor: default;
}


.subwindow__content__image {
  width: 100%;
  height: 100%;
  object-fit: fill;
  border: none;
  /* transform: rotate('90deg') */

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.subwindow__bottom {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 0;
  padding-left: 10px;
  margin: 0;
  background-color: rgba(100, 100, 100);
  color: rgb(255, 255, 255);
  border-top: 1px dashed rgba(100, 100, 100, 0.5);
  height: 25px;
  font-size: 0.9rem;
  box-sizing: border-box;
}

.thermal__scalebar__container {
  width: calc(100% - 10rem);
  height: 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
}

.subwindow__img__scale__bar {
  width: 100%;
  height: 100%;
  display: block;
}

.thermal__scalebar__marker {
  position: absolute;
  top: 0;
  left: 50%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  line-height: 10px;
  text-shadow: 1px 1px 0px rgb(0, 0, 0)
}


.lbl__thermal__scale__value {
  width: 40px;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
  text-align: center;
  color: white;
}

.input__thermal__scale__value {
  width: 40px;
  font-size: 0.9rem;
  padding: 0;
  margin: 0;
  background-color: rgba(255, 0, 0, 0.5);
  border: none;
  border-radius: 5px;
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
  text-align: center;
  color: white;
  display: none;
}

.lbl__current_temp {
  width: 80px;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  color: yellow;
}


.subwindow__title {
  float: left;
  font-weight: bold;
  cursor: move;

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}


/****************
* E: sub floating window 
*********************/