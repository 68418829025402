.layout {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.toggle__container {
  width: 100%;
  height: 100%;
  background-color: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.toggle__button {
  width: 150px;
  height: 40px;
  border-radius: 10px;
  background-color: var(--color-main-bgcolor1);
  color: var(--color-main-bgcolor1-text);
  border: none;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.active {
  background-color: #007bff;
  color: #ffffff;
  border-color: #0056b3;
}

.disabled {
  background-color: rgba(50, 50, 50, 0.5);
  color: rgba(200, 200, 200, 0.5);
}

.disabled:hover {
  cursor: default;
  filter: brightness(1.0);
  font-weight: normal;
}