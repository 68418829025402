.layout {
  width: calc(100vw - var(--layout-content-list-width) - var(--layout-sidebar-width));
  /* width: 50%; */
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  /* transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out 0.5s; */
  transition: all 0.5s;
}

.layout.visible {
  opacity: 1;
  visibility: visible;
}

.layout.hidden {
  opacity: 0;
  visibility: hidden;
}


.center__container {
  width: 98%;
  height: 95%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
}


.center__top {
  width: 90%;
  height: 80px;
  background-color: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.menu__label {
  flex: 1;
}


.description__layout {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
  /* font-size: 1rem; */
}

.description__row {
  padding: 0;
  margin: 0;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.large__txt {
  font-size: 1rem;
  font-weight: bold;
}

.center__middle {
  position: relative;
  height: 95%;
  width: 95%;
  /* border-radius: 20px; */
  background-color: gray;
}

.arrow__container {
  position: absolute;
  width: 20px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  font-size: 3rem;
  color: white;
  text-shadow: 1px 1px 0 black;
  z-index: var(--zindex-overlay-contentviewer-arrow);
}

.disabled {
  /* background-color: rgba(50, 50, 50, 0.5); */
  color: rgba(200, 200, 200, 0.5);
}

.disabled:hover {
  cursor: default;
  filter: brightness(1.0);
  font-weight: normal;
}


.arrow__container.left {
  left: 0;
  top: 0;
  /* border-radius: 20px 0 0 20px; */
  float: left;
}


.arrow__container.right {
  right: 0;
  top: 0;
  /* border-radius: 0 20px 20px 0; */
  float: right;
}


.arrow__container:hover {
  background-color: rgba(200, 200, 200, 0.5);
  cursor: pointer;
}

.blank__view {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(200, 200, 200);
}

.overlaymsg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--zindex-overlay-contentviewer-arrow);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  font-size: 1.2rem;
  border-radius: 20px;
  width: 50%;
  background-color: var(--color-overlay-bgcolor-opacity-light);
  color: black;
  animation: blink 1s infinite;

}

.center__footer {
  width: 100%;
  height: 80px;
  background-color: none;
}

.pages {
  height: 1.5rem;
  font-size: 0.8rem;
  color: white;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.toggle__top {
  width: 30%;
  max-width: 300px;
}

.toggle_bottom {
  width: 100%;
}

@keyframes blink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}