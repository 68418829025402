.container {
  padding: 20px;
}

.form {
  margin-bottom: 20px;
}

.chipContainer {
  margin-top: 10px;
}

.chipContainer .MuiTextField-root {
  margin-bottom: 10px;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}