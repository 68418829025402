.layout {
  z-index: var(--zindex-overlay-detailviewer-keymap);
  width: 250px;


  border: 1px solid rgb(100, 100, 100);
  border-radius: 5px;
  background-color: var(--color-overlay-bgcolor-opacity-light);
  color: black;
  font-size: 0.8rem;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  /* resize: both; */
}


.isPinned {
  position: static;
  width: 95%;
  height: auto;
  resize: none;
  border: none;
  padding: 5px;
  border-radius: 10px;
}

.isNotPinned {
  position: absolute;
  right: 10px;
  top: 10px;

}

.topbar {
  background-color: rgba(50, 50, 50, 1);
  height: 25px;
  font-size: 0.8rem;

  border-radius: 5px 5px 0 0;
  color: white;
  cursor: move;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid rgb(200, 200, 200);

}

.btn__minimize {
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  border: none;
  /* border: 1px solid white; */
  border-radius: 1px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: none;
  padding: none;
}

.btn__pinned {
  width: 20px;
  height: 20px;
  font-size: 0.8rem;
  border: none;
  /* border: 1px solid white; */
  border-radius: 1px;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  margin: none;
  padding: none;
}


.content {
  width: 100%;
  height: 200px;
  overflow-y: auto;
  margin: 0;
  border: none;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(250, 250, 250);
  border-radius: 0 0 5px 5px;
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
}