.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: stretch;
  align-items: stretch;
  /* background: var(--color-main-bgcolor1); */
}


.content {
  width: 100vw;
  height: calc(100vh - var(--layout-header-height) - var(--layout-footer-height));
  background-color: rgb(245, 245, 245);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.title__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(20, 20, 20);
}

.subtitle {
  font-size: 1.0rem;
  color: rgb(80, 80, 80);
}


.button__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 100px;
}


.btn__menu {
  width: 20vw;
  height: 20vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  gap: 20px;
  font-size: 1.2rem;
  font-weight: bold;
}

.btn__image {
  width: 10vw;
  height: 10vw;
}

.reload:hover {
  cursor: pointer;
  color: tomato;
}