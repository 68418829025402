.canvas__container {
  width: 100%;
  height: 100%;
  max-width: 100vw;
  max-height: 100vh;
  background-color: gray;
  overflow: auto;
}

.canvas__itself {
  background-color: gray;
}