.layout {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: rgb(0, 0, 0);
    flex-direction: column;
}

.toparea {
    width: 100%;
    height: calc(14vw - 20px);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}


.toparea>div {
    width: 15vw;
    height: 12vw;
}


.bottomarea {
    width: 100%;
    height: calc(100% - 14vw);
}

.leftarea {
    width: 32vh;
    height: calc(100% - 2px);
    display: flex;
    flex-direction: column;
}

.leftarea>div {
    width: 100%;
    height: 33%;
}

.rightarea {
    width: calc(100% - 33vh);
    height: calc(100% - 5px);
}