.layout {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgb(200, 200, 200, 0.5);
  font-size: 2rem;
  ;
}

.layout:hover {
  cursor: pointer;
}

.selected__image {
  max-height: 80%;
  max-width: 80%;
}