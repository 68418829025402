.layout {
  display: flex;
  flex-direction: column;
  width: 80%;
  font-size: 0.8rem;
}


.select__header {
  box-sizing: content-box;
  width: 100%;
  height: 30px;
  background-color: none;
  font-weight: bold;
  border-bottom: 2px solid rgba(50, 50, 50, 0.5);
  padding-left: 10px;

  display: flex;
  justify-content: left;
  align-items: center;
}


.range__container {
  width: 100%;
  /* height: 200px; */
  /* padding: 5px 0px 5px 0px; */
  font-size: 1rem;
  /* filter: brightness(1.5); */
  background-color: none;
  border: none;
  border-radius: 0 0 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}