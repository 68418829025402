.layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: stretch;
    align-items: stretch;
}

.header {
    height: var(--layout-header-height);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--color-main-bgcolor1);
    color: var(--color-main-bgcolor1-text);
    border-bottom: 1px solid var(--color-main-bgcolor2);
}

.center {
    display: flex;
    flex-direction: row;
    width: 100vw;
    justify-content: stretch;
    flex: 1;
}



.footer {
    width: 100%;
    height: var(--layout-footer-height);
    box-sizing: border-box;
    background: var(--color-main-bgcolor1);
    color: var(--color-main-bgcolor1-text);
    display: flex;
    justify-content: stretch;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    border-top: 1px solid rgb(100, 100, 100);
}